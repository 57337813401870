import React from 'react'

const IconPassword = (params) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...params}>
            <path d="M15.073 3.00008C14.3218 2.99969 13.578 3.1473 12.8839 3.43448C12.1898 3.72166 11.5591 4.14278 11.0278 4.67378C10.4965 5.20479 10.0751 5.83527 9.78754 6.5292C9.5 7.22314 9.352 7.96693 9.352 8.71808C9.35155 9.28002 9.43514 9.83887 9.6 10.3761L3 16.9761V20.7881H6.814L13.414 14.1881C14.2314 14.4388 15.0946 14.5031 15.94 14.3762C16.7855 14.2492 17.5918 13.9343 18.2995 13.4546C19.0072 12.9749 19.5983 12.3425 20.0293 11.6042C20.4603 10.8658 20.7202 10.0402 20.79 9.18808C20.9183 7.83203 20.5455 6.47594 19.742 5.37608C19.2093 4.63651 18.5075 4.03499 17.6952 3.62169C16.8828 3.20838 15.9834 2.99526 15.072 3.00008H15.073ZM15.073 13.1651C14.6424 13.1636 14.2142 13.0996 13.802 12.9751L13.072 12.7501L12.532 13.2901L10.511 15.3131L9.611 14.4361L8.711 15.3361L9.587 16.2121L8.577 17.2221L7.701 16.3461L6.801 17.2461L7.677 18.1221L6.277 19.5221H4.273V17.5051L10.499 11.2591L11.039 10.7191L10.855 10.1191C10.5334 9.11267 10.5732 8.02556 10.9675 7.04536C11.3619 6.06517 12.086 5.25337 13.015 4.75008C13.6831 4.40066 14.4291 4.22686 15.1829 4.24501C15.9367 4.26316 16.6734 4.47266 17.324 4.85383C17.9745 5.23499 18.5175 5.7753 18.9018 6.42398C19.2861 7.07267 19.4992 7.80841 19.521 8.56208C19.5652 9.46098 19.3229 10.3507 18.829 11.1031C18.4269 11.7344 17.8719 12.254 17.2155 12.6138C16.5591 12.9735 15.8225 13.1618 15.074 13.1611L15.073 13.1651Z" fill="#6A8997" />
            <path d="M16.979 8.08313C16.979 8.33456 16.9044 8.58033 16.7647 8.78937C16.625 8.9984 16.4265 9.16131 16.1942 9.25748C15.9619 9.35365 15.7063 9.37876 15.4597 9.32964C15.2131 9.28052 14.9866 9.15937 14.8089 8.98151C14.6312 8.80366 14.5102 8.5771 14.4613 8.33048C14.4124 8.08386 14.4377 7.82828 14.534 7.59605C14.6304 7.36382 14.7935 7.16539 15.0026 7.02585C15.2118 6.88631 15.4576 6.81194 15.709 6.81213C16.0459 6.8124 16.369 6.94642 16.6071 7.18475C16.8452 7.42308 16.979 7.74622 16.979 8.08313V8.08313Z" fill="#6A8997" />
        </svg>
    )
}

export default IconPassword
