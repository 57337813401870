import React from 'react'
import IconLogoFullyWhite from '../../assets/icons/IconLogoFullyWhite'
import { HelmetLogin } from '../../components/Helmet'
import styles from './Login.module.css'
import LoginRegisterCard from './LoginRegisterCard'

const Login = () => {
    return (
        <div className={styles.container}>
            <HelmetLogin />
            <div className={styles.innerContainer}>
                <div className={styles.featureContainer}>
                    <IconLogoFullyWhite width="200px" height="200px" />
                </div>
                <div className={styles.loginContainer}>
                    <LoginRegisterCard />
                </div>
            </div>
        </div>
    )
}

export default Login
