import React from 'react'
import styles from './Text.module.css'

const Text = ({ size, bold = false, color = "black", text = '', center = false, type = 'normal', onClick }) => {
    let style = styles.normal
    switch (type) {
        case 'subTitle':
            style = styles.subTitle
            break
        case 'title':
            style = styles.title
            break
        case 'medium':
            style = styles.medium
            break
        case 'small':
            style = styles.small
            break
        case 'smallError':
            style = styles.smallError
            break
        case 'smallSuccess':
            style = styles.smallSuccess
            break
        case 'smallInfo':
            style = styles.smallInfo
            break
        case 'success':
            style = styles.success
            break
        case 'error':
            style = styles.error
            break
        default:
            break
    }
    let textColor = false
    switch (color) {
        case "green":
            textColor = styles.green
            break
        case "red":
            textColor = styles.red
            break
        case "white":
            textColor = styles.white
            break
        case "whiteSecondary":
            textColor = styles.whiteSecondary
            break
        case "gray":
            textColor = styles.gray
            break
        case "darkGray":
            textColor = styles.darkGray
            break
        case "info":
            textColor = styles.info
            break
        default:
            break
    }

    return (
        <p
            onClick={onClick}
            className={[style, textColor, bold ? styles.bold : styles.normalWeight, onClick ? styles.link : null, center ? styles.center : null].join(' ')}
            style={size ? { 'fontSize': size + 'px' } : null}>
            {text}
        </p>
    )
}

export default Text
