import React from 'react'
import styles from './Layout.module.css'

const Layout = ({
    children,
    padding = [0],
    margin = [0],
    spacing = 0,
    alignItems = 'center',
    justifyContent = 'center',
    fullWidth = false,
    row = false,
    fitParent = false,
    bottomBorder = false,
    border = false,
    hide = false,
    card = false,
    page=false,
    cardSecondary = false,
    cardSecondaryNoOverflow = false,
    gap=0,
    classes = [],
    color,
    style = {},
    onClick,
    hoverEffect,
}) => {
    const containerStyle = {
        'alignItems': alignItems,
        'justifyContent': justifyContent,
        'padding': page ? '48px' : padding.map(p => p + 'px').join(' '),
        'gap': `${gap}px`,
        'margin': margin.map(p => p + 'px').join(' '),
        ...style,
    }
    if (color) containerStyle.background = color

    if (hide) return null
    return (
        <div
            onClick={onClick}
            className={[
                styles.base,
                fullWidth ? styles.fullWidth : null,
                page ? styles.page : null,
                row ? styles.row : null,
                fitParent ? styles.fitParent : null,
                bottomBorder ? styles.bottomBorder : null,
                border ? style.border : null,
                card ? styles.card : null,
                cardSecondary ? styles.cardSecondary : null,
                cardSecondaryNoOverflow ? styles.cardSecondaryNoOverflow : null,
                hoverEffect ? styles.hoverEffect : null,
                ...classes
            ].join(' ')}
            style={containerStyle}>
            {React.Children.map(children, (child, i) => {
                if (child === null) return null
                if (spacing === 0 || i === children.length - 1)
                    return child
                return (
                    <>
                        {child}
                        <div style={
                            row ?
                                { width: spacing + 'px' } :
                                { height: spacing + 'px' }
                        } />
                    </>
                );
            })}
        </div>
    )
}

export default Layout
