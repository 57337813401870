import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import userReducer from './user/user.slice'

export const rootReducer = combineReducers({
    user: userReducer,
});

export default configureStore({
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
    reducer: rootReducer,
})