import { getApp } from "@firebase/app"
import {
    doc,
    getDoc,
    getFirestore,
} from "@firebase/firestore";

export default class DatabaseService {
    static getUser = async (uid) => {
        const db = getFirestore(getApp('coddy'))
        const ref = doc(db, 'users', uid)
        const result = await getDoc(ref)
        return result.data()
    }
}