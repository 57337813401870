import React from 'react'

const IconGoogle = (params) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...params}>
            <g clipPath="url(#clip0_827_265)">
                <path d="M6.43271 14.0869L5.73682 16.6855L3.19217 16.7394C2.42244 15.3059 2.01345 13.7066 2.00056 12.0796C1.98766 10.4526 2.37125 8.84696 3.11815 7.40149V7.40149L5.38364 7.81661L6.37559 10.0692C5.92921 11.375 5.94911 12.795 6.43191 14.0877L6.43271 14.0869Z" fill="#FBBB00" />
                <path d="M21.8254 10.132C22.1649 11.9151 22.0125 13.757 21.3844 15.4601C20.7564 17.1632 19.6765 18.6631 18.2606 19.7989V19.7989L15.407 19.6533L15.0048 17.1328C16.1829 16.442 17.088 15.3676 17.5687 14.0893H12.2212V10.1336H21.827L21.8254 10.132Z" fill="#518EF8" />
                <path d="M18.2599 19.7981C17.1156 20.7166 15.7833 21.3722 14.3574 21.7184C12.9315 22.0645 11.4469 22.0927 10.0088 21.8009C8.5708 21.5092 7.21455 20.9047 6.03626 20.0302C4.85797 19.1558 3.88648 18.0328 3.19067 16.7409L6.43122 14.0861C6.73556 14.8971 7.21338 15.6319 7.83129 16.239C8.44919 16.8461 9.19226 17.311 10.0085 17.601C10.8248 17.891 11.6946 17.9992 12.557 17.918C13.4195 17.8369 14.2538 17.5683 15.0016 17.1311L18.2599 19.7981Z" fill="#28B446" />
                <path d="M18.3846 4.30248L15.1449 6.95734C14.3859 6.48436 13.5301 6.18863 12.641 6.09217C11.7519 5.99571 10.8526 6.10101 10.0098 6.40022C9.16708 6.69943 8.40267 7.18485 7.77349 7.82036C7.1443 8.45586 6.66655 9.22509 6.37578 10.0708L3.11914 7.40143C3.80642 6.0748 4.78218 4.91898 5.97472 4.01889C7.16727 3.1188 8.54631 2.49731 10.0105 2.20007C11.4748 1.90282 12.987 1.93738 14.4361 2.3012C15.8852 2.66502 17.2344 3.34885 18.3846 4.30248V4.30248Z" fill="#F14336" />
            </g>
            <defs>
                <clipPath id="clip0_827_265">
                    <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconGoogle
