import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import IconLogout from '../../assets/icons/IconLogout'
import Divider from '../../components/Divider/Divider'
import IconButton, { CopyButtonWithSelection } from '../../components/IconButton'
import InformationPopup from '../../components/InformationPopup'
import Layout from '../../components/Layout/Layout'
import Text from '../../components/Text/Text'
import AuthService from '../../services/AuthService'
import Analytics from './Analytics'

const COPY_ITEMS = (refId) => [
  { id: 'ref', value: refId },
  { id: 'ref_full', value: `?ref=${refId}` },
  { id: 'main_link', value: `https://coddy.tech/?ref=${refId}` },
]

const Home = () => {
  const displayName = useSelector(state => state.user.data?.displayName)
  const email = useSelector(state => state.user.data?.email)
  const uid = useSelector(state => state.user.data?.uid)
  const refId = uid.slice(0, 6)

  const handleLogout = () => {
    AuthService.logout()
  }

  const handleCopyRefId = () => {
    navigator.clipboard.writeText(refId);
    toast.info('Copied reference id')
  }

  const handleCopy = (id) => {
    navigator.clipboard.writeText(COPY_ITEMS(refId).find(item => item.id === id).value);
    toast.info('Copied to clipboard')
  }

  return (
    <Layout
      color={'#29ABE2'}
      style={{ minHeight: '100vh' }}
      padding={[32]}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      spacing={32}>
      <Layout spacing={32} alignItems='flex-start'>
        <Layout alignItems='flex-start'>
          <Layout row spacing={16}>
            <Text text={displayName} type='title' color='white' />
            <IconButton Icon={IconLogout} label='Logout' size='small' onClick={handleLogout} />
          </Layout>
          <Text text={email} type='normal' color='whiteSecondary' />
        </Layout>
        <Layout row spacing={8}>
          <Text text='Reference id:' color='whiteSecondary' />
          <Text text={uid.slice(0, 6)} color='white' onClick={handleCopyRefId} />
          <CopyButtonWithSelection items={COPY_ITEMS(refId)} onItemClick={handleCopy} />
          <InformationPopup
            text={<>Your reference id is used to create affiliation links, append <b>?ref={refId}</b> as suffix to any url in coddy.tech<br /><br />Examples:<br />https://coddy.tech/?ref={refId}<br />https://coddy.tech/courses?ref={refId}</>} />
        </Layout>
      </Layout>
      <Divider color={'rgba(255, 255, 255, 0.3)'} />
      <Analytics />
    </Layout>
  )
}

export default Home