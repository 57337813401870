import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import Loader from '../../components/Loader/Loader'
import Text from '../../components/Text'
import Status from '../../store/status'
import { fetchAnalytics } from '../../store/user/user.slice'

const Analytics = () => {
    const dispatch = useDispatch()
    const analyticsData = useSelector(state => state.user.analyticsData)
    const fetchAnalyticsStatus = useSelector(state => state.user.fetchAnalyticsStatus)

    useEffect(() => {
        dispatch(fetchAnalytics())
    }, [dispatch])

    return (
        <Layout spacing={16} alignItems='flex-start'>
            <Text text='Analytics' type='title' color='white' />
            {
                fetchAnalyticsStatus === Status.Success ?
                    <Layout spacing={8} alignItems='flex-start'>
                        <Layout row spacing={8}>
                            <Text text={'Registered - '} color='whiteSecondary' />
                            <Text text={analyticsData?.registeredUsers} color='white' />
                        </Layout>
                        <Layout row spacing={8}>
                            <Text text={'Subscribed - '} color='whiteSecondary' />
                            <Text text={analyticsData?.premiumUsers} color='white' />
                        </Layout>
                    </Layout> :
                    <Loader />
            }
        </Layout>
    )
}

export default Analytics