import React from 'react'
import IconLogoWhite from '../../assets/icons/IconLogoWhite'
import { HelmetSplash } from '../../components/Helmet'
import styles from './Splash.module.css'

const Splash = () => {
    return (
        <div className={styles.container}>
            <HelmetSplash />
            <IconLogoWhite className={styles.logo} />
        </div>
    )
}

export default Splash
