import React from 'react'

const IconLogoWhite = ({className}) => {
    return (
        <svg  width="64" height="59" viewBox="0 0 64 59" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M24.7798 29.0836C24.7798 30.0641 23.9824 30.8616 23.0019 30.8616C22.0214 30.8616 21.224 30.0641 21.224 29.0836C21.224 28.1032 22.0214 27.3057 23.0019 27.3057C23.9824 27.3057 24.7798 28.1032 24.7798 29.0836ZM30.1496 7.5018C30.1496 6.52105 30.9471 5.72361 31.9275 5.72361C32.908 5.72361 33.7057 6.52105 33.7057 7.5018C33.7057 8.48198 32.908 9.27971 31.9275 9.27971C30.9471 9.27971 30.1496 8.48198 30.1496 7.5018V7.5018ZM32.7116 19.3282V10.7507C34.1897 10.3923 35.2736 9.0407 35.2736 7.5018C35.2736 5.65682 33.7725 4.15572 31.9275 4.15572C30.0828 4.15572 28.5817 5.65682 28.5817 7.5018C28.5817 9.0407 29.6656 10.3923 31.1437 10.7507V18.7328L24.5709 26.1317C24.0875 25.8731 23.5501 25.7376 23.0019 25.7376C21.1569 25.7376 19.6558 27.2387 19.6558 29.0836C19.6558 30.9286 21.1569 32.4294 23.0019 32.4294C24.8469 32.4294 26.3477 30.9286 26.3477 29.0836C26.3477 28.3993 26.1354 27.7295 25.7452 27.1707L32.7116 19.3282Z" fill="#FFFFFF" />
            <path d="M33.8502 50.5125C33.8502 51.493 33.0525 52.2907 32.0723 52.2907C31.0919 52.2907 30.2941 51.493 30.2941 50.5125C30.2941 49.532 31.0919 48.7343 32.0723 48.7343C33.0525 48.7343 33.8502 49.532 33.8502 50.5125ZM42.7759 28.9307C42.7759 29.9108 41.9784 30.7086 40.998 30.7086C40.0175 30.7086 39.2201 29.9108 39.2201 28.9307C39.2201 27.9499 40.0175 27.1525 40.998 27.1525C41.9784 27.1525 42.7759 27.9499 42.7759 28.9307ZM40.998 25.5849C39.153 25.5849 37.6522 27.0857 37.6522 28.9307C37.6522 29.6147 37.8645 30.2846 38.2544 30.8436L31.2883 38.6859V47.2633C29.8105 47.6217 28.7263 48.9733 28.7263 50.5125C28.7263 52.3572 30.2274 53.8583 32.0723 53.8583C33.9173 53.8583 35.4184 52.3572 35.4184 50.5125C35.4184 48.9733 34.3342 47.6217 32.8564 47.2633V39.2815L39.4289 31.8823C39.9124 32.1409 40.4498 32.2765 40.998 32.2765C42.8429 32.2765 44.3441 30.7754 44.3441 28.9307C44.3441 27.0857 42.8429 25.5849 40.998 25.5849" fill="#FFFFFF" />
            <path d="M25.0918 6.83135C25.0903 5.06651 25.0616 3.30111 25.1011 1.53712C25.1244 0.487033 24.7212 -0.00546907 23.637 0.0155612C19.972 0.0871777 16.2974 -0.18252 12.6441 0.271051C9.5669 0.652721 7.76967 2.57244 6.83439 5.3831C6.3345 6.88477 6.15063 8.4575 6.12477 10.0473C6.06906 13.4337 6.18018 16.8196 5.86928 20.2063C5.54643 23.7246 4.29911 25.4354 0.942236 26.4187C0.53243 26.539 0.0382198 26.5986 0.0228734 27.111C-0.0157768 28.407 -0.00213577 29.7057 0.0390721 31.0019C0.0529975 31.4353 0.458256 31.6232 0.828558 31.6752C4.52817 32.193 5.71723 35.142 5.83574 38.5108C5.95027 41.7569 6.00256 45.0055 6.13471 48.251C6.18189 49.4062 6.30722 50.5697 6.52775 51.7042C7.24676 55.3996 9.88918 57.7788 13.6922 57.9391C17.0767 58.082 20.4716 57.9712 23.8615 57.9962C24.7647 58.003 25.0969 57.5796 25.0935 56.7199C25.0798 53.0976 25.077 49.4747 25.0957 45.8524C25.1006 44.9046 24.6939 44.5212 23.7509 44.538C18.6 44.6287 19.5938 43.8366 19.4821 48.6264C19.4682 49.23 19.4801 49.8339 19.4795 50.4378C19.477 52.9751 19.477 52.9751 16.8704 52.9686C12.1215 52.9563 11.4562 52.2808 11.4355 47.4692C11.4201 43.8045 11.5665 40.1324 10.9106 36.4971C10.3714 33.5099 8.99681 31.0849 6.25464 29.5991C5.54047 29.2121 5.43929 28.9159 6.2032 28.4965C10.0094 26.4051 11.0348 22.9019 11.2095 18.8805C11.3468 15.7257 11.3982 12.5723 11.4625 9.41693C11.5273 6.23795 12.5978 5.11682 15.7819 5.06737C20.2278 4.99831 19.3917 4.57543 19.4764 8.55611C19.5028 9.8094 19.5102 11.0647 19.475 12.3177C19.4486 13.253 19.8189 13.6804 20.7794 13.6653C25.9608 13.584 24.9875 14.3701 25.0903 9.61814C25.1105 8.68968 25.0932 7.76037 25.0918 6.83135Z" fill="#29ABE2" />
            <path d="M38.908 6.83156C38.9097 5.06673 38.9381 3.30132 38.8989 1.53734C38.8753 0.487247 39.2789 -0.00525544 40.3628 0.0157748C44.028 0.0873913 47.7023 -0.182307 51.3559 0.271264C54.4332 0.652935 56.2304 2.57265 57.1657 5.38332C57.6653 6.88499 57.8491 8.45771 57.8753 10.0475C57.9307 13.4339 57.8199 16.8198 58.1308 20.2065C58.4533 23.7248 59.7007 25.4356 63.0575 26.419C63.4676 26.5392 63.9616 26.5989 63.9769 27.1112C64.0158 28.4072 64.0022 29.7059 63.9607 31.0021C63.9468 31.4355 63.5415 31.6234 63.1715 31.6754C59.4719 32.1932 58.2828 35.1422 58.164 38.5111C58.0495 41.7571 57.9972 45.0057 57.8651 48.2512C57.8182 49.4064 57.6928 50.5699 57.472 51.7044C56.753 55.3998 54.1106 57.779 50.3075 57.9393C46.9231 58.0823 43.5284 57.9714 40.1383 57.9964C39.2354 58.0033 38.9029 57.5798 38.9063 56.7201C38.9202 53.0978 38.9231 49.4749 38.904 45.8526C38.8992 44.9048 39.3059 44.5214 40.2491 44.5382C45.3998 44.6289 44.4063 43.8368 44.5177 48.6266C44.5319 49.2302 44.5197 49.8341 44.5202 50.438C44.5231 52.9753 44.5231 52.9753 47.1297 52.9688C51.8785 52.9566 52.5438 52.281 52.5643 47.4694C52.5796 43.8047 52.4336 40.1327 53.0892 36.4973C53.6286 33.5101 55.0032 31.0851 57.7454 29.5994C58.4596 29.2123 58.5605 28.9162 57.7969 28.4967C53.9904 26.4053 52.9653 22.9021 52.7905 18.8808C52.6533 15.7259 52.6018 12.5725 52.5376 9.41714C52.4728 6.23817 51.4022 5.11703 48.2179 5.06758C43.7719 4.99852 44.608 4.57564 44.5236 8.55632C44.4969 9.80961 44.4898 11.0649 44.5251 12.3179C44.5512 13.2532 44.1809 13.6806 43.2206 13.6655C38.0389 13.5843 39.0126 14.3703 38.9094 9.61835C38.8895 8.6899 38.9066 7.76059 38.908 6.83156Z" fill="#29ABE2" />
        </svg>

    )
}

export default IconLogoWhite
