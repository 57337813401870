import React from 'react'

const IconQuestion = ({ size = 24, color = 'black' }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke={color} strokeWidth="1.8" />
            <path d="M10 8.484C10.5 7.494 11 7 12 7C13.246 7 14 7.989 14 8.978C14 9.967 13.5 10.461 12 11.451V13M12 16.5V17" stroke={color} strokeWidth="1.8" strokeLinecap="round" />
        </svg>
    )
}

export default IconQuestion