import React from 'react'
import styles from './InformationPopup.module.css'
import Layout from '../Layout'
import IconQuestion from '../../assets/icons/IconQuestion'
import Text from '../Text'

const InformationPopup = ({ text }) => {
    return (
        <Layout classes={[styles.container]}>
            <IconQuestion color='rgba(255, 255, 255, 0.6)' />
            <Layout classes={[styles.popupContainer]} padding={[8, 0, 0, 0]}>
                <Layout classes={[styles.popupInnerContainer]} padding={[8]}>
                    <Text text={text} type='medium' />
                </Layout>
            </Layout>
        </Layout>
    )
}

export default InformationPopup