import React, { useState } from 'react'
import { toast } from 'react-toastify'
import IconEmail from '../../assets/icons/IconEmail'
import IconGoogle from '../../assets/icons/IconGoogle'
import IconPassword from '../../assets/icons/IconPassword'
import Button, { TYPE_HOME, TYPE_HOME_WHITE } from '../../components/Button/Button'
import AuthService from '../../services/AuthService'
import { openInNewTab } from '../../util/functions'
import styles from './LoginRegisterCard.module.css'

const Input = ({ Icon, placeholder, type, value, onChange, invalid }) => {
    return (
        <div className={[styles.inputContainer, invalid ? styles.inputContainerInvalid : null].join(' ')}>
            <Icon />
            <input value={value} onChange={onChange} className={styles.input} placeholder={placeholder} type={type} />
        </div>
    )
}

/* function validateEmail(email) {
    return /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
} */

function validatePass(pass) {
    return pass !== '' && pass.length >= 6
}

const LoginRegisterCard = () => {
    //const history = useHistory()

    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [invalidPass, setInvalidPass] = useState(false)
    
    const handleGoogleLogin = () => {
        AuthService.loginWithGoogle()
            .then(() => toast.success("Logged in Successfully!"))
            .catch(e => toast.error(e))
    }

    const handleMainButtonClick = () => {
        const isValidEmail = true
        const isValidPass = validatePass(pass)

        setInvalidEmail(!isValidEmail)
        setInvalidPass(!isValidPass)

        if (!isValidPass || !isValidEmail) return
        AuthService.loginWithEmailAndPassword(email, pass)
            .then(() => toast.success("Logged in Successfully!"))
            .catch((e) => toast.error(e))

    }

    return (
        <div className={styles.container}>
            <Input
                value={email}
                onChange={s => { setEmail(s.target.value); setInvalidEmail(false) }}
                Icon={IconEmail}
                invalid={invalidEmail}
                placeholder={"Email Address"}
                type={"email"} />
            <Input
                value={pass}
                onChange={s => { setPass(s.target.value); setInvalidPass(false) }}
                Icon={IconPassword}
                invalid={invalidPass}
                placeholder={"Password"}
                type={"password"} />
            <Button
                onClick={handleMainButtonClick}
                type={TYPE_HOME}
                text={"LOGIN"}
                classNameContainer={styles.mainButton} />
            <div className={styles.orContainer}>
                <div className={styles.divider} />
                <p className={styles.or}>Or</p>
                <div className={styles.divider} />
            </div>
            <Button
                onClick={handleGoogleLogin}
                Icon={IconGoogle}
                type={TYPE_HOME_WHITE}
                text={"Continue with Google"}
                classNameContainer={styles.mainButton}
                classNameIcon={styles.googleIcon} />
            <p className={styles.termsOfUse}>By continuing you agree to <span onClick={() => openInNewTab("https://coddy.tech/terms")}>Terms of use</span></p>
        </div>
    )
}

export default LoginRegisterCard
