import { useEffect } from 'react';
import { initializeApp } from "@firebase/app";
import Login from './pages/Login/Login';
import AuthService from './services/AuthService';
import { fetchUser } from './store/user/user.slice';
import { useDispatch, useSelector } from 'react-redux';
import Home from './pages/Home/Home';
import Splash from './pages/Splash/Splash';
import Status from './store/status';

const firebaseConfig = {
  apiKey: "AIzaSyD_orYcTk3E96vkO_jbuqtLW29i56rr3Pc",
  authDomain: "coddy-affiliation.firebaseapp.com",
  projectId: "coddy-affiliation",
  storageBucket: "coddy-affiliation.appspot.com",
  messagingSenderId: "1039322746328",
  appId: "1:1039322746328:web:94a7f77e0acb0e444c36ad"
};

const CoddyfirebaseConfig = {
  apiKey: "AIzaSyAwpKz7TwTGkm-mIUDeCVwOP4TfpxBqvyU",
  authDomain: "coddy-15505.firebaseapp.com",
  databaseURL: "https://coddy-15505-default-rtdb.firebaseio.com",
  projectId: "coddy-15505",
  storageBucket: "coddy-15505.appspot.com",
  messagingSenderId: "733978470830",
  appId: "1:733978470830:web:e1055550dc24f1408fc03f",
  measurementId: "G-TZLB4EWQE3"
};

function App() {
  useEffect(() => {
    initializeApp(firebaseConfig)
    initializeApp(CoddyfirebaseConfig, 'coddy')
  }, [])

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  useEffect(() => {
    AuthService.setAuthChangeListener(async user => {
      dispatch(fetchUser(user))
    })
  }, [dispatch])

  if (user.fetchStatus === Status.Loading || user.fetchStatus === Status.None) {
    return (<Splash />)
  }

  if (!user.isLogged)
    return <Login />

  return (
    <Home />
  );
}

export default App;
