import React from 'react'

const IconEmail = ({ color, className }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M5.473 6H17.843C18.2335 6.00027 18.6079 6.15557 18.8839 6.43179C19.1599 6.708 19.315 7.08251 19.315 7.473V16.309C19.3147 16.6993 19.1596 17.0736 18.8836 17.3496C18.6076 17.6256 18.2333 17.7807 17.843 17.781H5.473C5.08251 17.781 4.708 17.6259 4.43179 17.3499C4.15557 17.0739 4.00027 16.6995 4 16.309V7.473C4 7.08234 4.15519 6.70767 4.43143 6.43143C4.70767 6.15519 5.08234 6 5.473 6V6Z" stroke="#6A8997" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.35596 8.35596L11.656 12.479L16.956 8.35596" stroke="#6A8997" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconEmail
