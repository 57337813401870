import React from 'react'
import styles from './ButtonBase.module.css'

const ButtonBase = ({
    children,
    className,
    onClick,
    style,
    withBorder,
    disabled
}) => {
    return (
        <button
            onClick={onClick} 
            className={[
                styles.button, 
                withBorder ? null : styles.withoutBorder, 
                disabled ? styles.disabled : null,
                className].join(' ')}
            style={style}>
            {children}
        </button>
    )
}

export default ButtonBase

