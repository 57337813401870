import { Helmet } from "react-helmet";


/* Splash Page */
export const HelmetSplash = () =>
    <Helmet>
        <title>Coddy Affiliation</title>
    </Helmet>

/* Login Page */
export const HelmetLogin = () =>
    <Helmet>
        <title>Login - Coddy Affiliation</title>
    </Helmet>

/* Home Page */
export const HelmetHome = () =>
    <Helmet>
        <title>Coddy Affiliation</title>
    </Helmet>
