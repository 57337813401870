import React from 'react'
import styles from './CopyButtonWithSelection.module.css'
import Layout from '../Layout'
import Text from '../Text'
import IconButton from './IconButton'
import IconCopy from '../../assets/icons/IconCopy'

const CopyButtonWithSelection = ({ onItemClick, items }) => {
    return (
        <Layout classes={[styles.container]}>
            <IconButton
                Icon={IconCopy}
                onClick={() => { }}
                size='small' />
            <Layout classes={[styles.menuContainer]} justifyContent='flex-start'>
                {
                    items.map((item, index) =>
                        <Layout
                            classes={[styles.itemContainer]}
                            padding={[8]}
                            onClick={() => onItemClick(item.id)}
                            fullWidth
                            alignItems='flex-start'
                            key={item.value + index}>
                            <Text text={item.value} type='small' />
                        </Layout>
                    )
                }
            </Layout>
        </Layout>
    )
}

export default CopyButtonWithSelection