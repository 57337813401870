import { toast } from "react-toastify"
import Status from "../status"

export const fetchUserLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchUserFulfilled = (state, action) => {
    state.fetchStatus = Status.Success
    if (action.payload === null) {
        state.data = {}
        state.isLogged = false
        state.accessToken = null;
        state.fetchStatus = Status.Success
    }
    else {
        if (action.payload?.isAffiliation) {
            state.data = action.payload
            state.isLogged = true
            state.accessToken = action.meta.arg.accessToken
            state.fetchStatus = Status.Success
        } else {
            state.data = {}
            state.isLogged = false
            state.accessToken = null;
            toast.error("No access, contact support")
            state.fetchStatus = Status.Success
        }
    }

}

export const fetchUserRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
    state.data = {}
    state.isLogged = false
    state.accessToken = null;
}

export const fetchAnalyticsLoading = (state, _) => {
    state.fetchAnalyticsStatus = Status.Loading
}

export const fetchAnalyticsFulfilled = (state, action) => {
    state.fetchAnalyticsStatus = Status.Success
    state.analyticsData = action.payload?.data ?? {}
}

export const fetchAnalyticsRejected = (state, action) => {
    state.fetchAnalyticsStatus = Status.Fail
    if (action.payload) {
        toast.error(action.payload)
    } else {
        toast.error(action.error.message)
    }
    state.analyticsData = {}
}

