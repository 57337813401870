import React from 'react'
import styles from './IconButton.module.css'

const IconButton = ({ Icon, onClick, type, size, disabled, label, text, hide, opacity }) => {

    let mainStyle = styles.main
    if (type === 'home')
        mainStyle = styles.home
    if (type === 'homeRed')
        mainStyle = styles.homeRed

    let sizeStyle = styles.normal
    if (size === 'small') sizeStyle = styles.small
    else if (size === 'verySmall') sizeStyle = styles.verySmall
    if (text) {
        if (sizeStyle === styles.normal) sizeStyle = styles.normalWithText
        else if (sizeStyle === styles.small) sizeStyle = styles.smallWithText
    }

    let iconSize = 24
    if (size === 'small') iconSize = 20
    else if (size === 'verySmall') iconSize = 14

    let iconColor = '#29ABE2'
    if (type === 'home' || type === 'homeRed') iconColor = 'white'

    if (hide) return null

    return (
        <div
            className={[styles.base, mainStyle, sizeStyle, disabled ? styles.disabled : null].join(' ')}
            onClick={disabled ? null : onClick}>
            <Icon size={iconSize} color={iconColor} style={{ opacity: opacity }} />
            {text ? <p className={styles.text}>{text}</p> : null}
            {label ? <span className={styles.tooltip}>{label}</span> : null}
        </div>
    )
}

export default IconButton
