import React from 'react'

const IconLogout = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9996 12L15.0763 8.93884M19.1776 12H11.9996H19.1776ZM11.9996 12L15.0763 15.0611L11.9996 12Z" stroke="#6A8997" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.1784 5.87852V4.86087C19.1784 4.3189 18.962 3.79913 18.5769 3.41591C18.1917 3.03268 17.6692 2.81738 17.1245 2.81738H6.87548C6.33075 2.81738 5.80833 3.03268 5.42314 3.41591C5.03796 3.79913 4.82156 4.3189 4.82156 4.86087V19.1416C4.82156 19.4099 4.87469 19.6756 4.97791 19.9236C5.08113 20.1715 5.23242 20.3968 5.42314 20.5865C5.61387 20.7763 5.84029 20.9268 6.08948 21.0295C6.33867 21.1322 6.60575 21.185 6.87548 21.185H17.1278C17.3975 21.185 17.6646 21.1322 17.9138 21.0295C18.163 20.9268 18.3894 20.7763 18.5801 20.5865C18.7709 20.3968 18.9222 20.1715 19.0254 19.9236C19.1286 19.6756 19.1817 19.4099 19.1817 19.1416V18.1215" stroke="#6A8997" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconLogout
