import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ApiService from '../../services/ApiService'
import DatabaseService from '../../services/DatabaseService'
import Status from '../status'
import {
  fetchUserLoading,
  fetchUserFulfilled,
  fetchUserRejected,
  fetchAnalyticsFulfilled,
  fetchAnalyticsLoading,
  fetchAnalyticsRejected,
} from './user.actions'

export const fetchUser = createAsyncThunk('user/fetchUser', async (user) => {
  if (user != null) {
    return await DatabaseService.getUser(user.uid)
  }
  return null
})

export const fetchAnalytics = createAsyncThunk('user/fetchAnalytics', async (_, { getState, rejectWithValue }) => {
  const isLogged = getState().user.isLogged
  const accessToken = getState().user.accessToken

  if (isLogged) {
    return await ApiService.getReferenceAnalytics(
      {
        "startDate": "2022-01-01",
        "endDate": new Date().toISOString().split('T')[0],
      },
      accessToken)
  }
  return rejectWithValue('User not logged')
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: {},
    analyticsData: {},
    fetchStatus: Status.None,
    fetchAnalyticsStatus: Status.None,
    isLogged: false,
    accessToken: null,
  },
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, fetchUserLoading)
      .addCase(fetchUser.fulfilled, fetchUserFulfilled)
      .addCase(fetchUser.rejected, fetchUserRejected)
      .addCase(fetchAnalytics.pending, fetchAnalyticsLoading)
      .addCase(fetchAnalytics.fulfilled, fetchAnalyticsFulfilled)
      .addCase(fetchAnalytics.rejected, fetchAnalyticsRejected)
  },
})

export default userSlice.reducer